import React from 'react';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Padding } from '@mui/icons-material';
import Stack from '@mui/material/Stack';
import Draggable from 'react-draggable';

const InsightsResponse = ({ message }) => {
  return (
    
<Box   padding={1}  justifyContent="flex-end">
<Draggable>
      <Card sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '400px', 
        margin: '0 auto', // Center the Card horizontally
      }} Padding={3} elevation={12} >
      <CardContent>
        <Typography gutterBottom variant="subtitle2" component="div">
        {message.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
      {message.text}
        </Typography>
      </CardContent>
    </Card>
    </Draggable>

    </Box>
   
  );
};

export default InsightsResponse;
