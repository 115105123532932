import SurveyForm from '../components/SurveyForm'
import React, { useState } from 'react'

export default function Feedback () {
  const [open, setOpen] = useState(false);  
  
  const handleOpen = () => setOpen(true);  
  const handleClose = () => setOpen(false);  

    const questions = [  
        {  
          text: 'How would you rate the overall user experience of the app? Please rate on a scale of 1-10 and provide specific reasons for your rating.',  
          answerType: 'slider',  
        },  
        {  
          text: 'What aspects of the app\'s navigation or layout do you think could be improved?',  
          answerType: 'textarea',  
        },  
        {  
          text: 'How well do you feel the app\'s design and visual elements align with its purpose and target audience? Please explain.',  
          answerType: 'textarea',  
        },  
        {  
            text: 'What additional features would you like to see implemented in the app to enhance your experience?',  
            answerType: 'textarea',  
        },  
        {  
          text: 'Were there any specific features or sections of the app that you found to be unnecessary or confusing?',  
          answerType: 'textarea',  
        },  
        {  
          text: 'How visually appealing did you find the overall design and layout of the app? Please rate on a scale of 1-10.',  
          answerType: 'slider',  
        },  
        {  
          text: 'Did you encounter any errors or issues while using the app? If so, please describe the problem and where it occurred.',  
          answerType: 'textarea',  
        },  
        {  
          text: 'Please share any additional feedback or suggestions you have to improve the overall user experience and interface of the app.',  
          answerType: 'textarea',  
        },  
        {  
            text: 'Would you like to generate an improved solution based on this feedback?',  
            answerType: 'yesNo',  
        },  
      ];  

    return (  
    <div>  
      <button onClick={handleOpen}>Open survey</button>  
      <SurveyForm questions={questions} open={open} handleClose={handleClose} />  
    </div>  
  );  
};  