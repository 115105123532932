import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import PersistentDrawer from "../components/PersistentDrawer";
import { Button, CardActions, Typography } from "@mui/material";
import { useEffect, useState, createContext } from "react";
import { useHistory } from "react-router-dom";
import ShareExisting from "./ShareExisting";
import axios from "axios";

export const DataContext = createContext();

const OpenExistingProject = (props) => {

  const [project, setProject] = useState([]);
  useEffect(() => {
    fetchProjectData();
    axios
      .get("http://127.0.0.1:8000/api/project/")
      .then((response) => {
        setProject(response.data);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log("projects:", project);

  const [userproject, setUserProject] = useState([]);
  useEffect(() => {
    axios
      .get("http://127.0.0.1:8000/api/userProject/")
      .then((response) => {
        setUserProject(response.data);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log("User projects:", userproject);

  const [user, setUser] = useState([]);
  useEffect(() => {
    axios
      .get("http://127.0.0.1:8000/api/user/")
      .then((response) => {
        setUser(response.data);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log("Users:", user);

  const fetchProjectData = () => {
    axios
      .get("http://127.0.0.1:8000/api/project/")
      .then((response) => {
        setProject(response.data);
      })
      .catch((err) => console.log(err));
  };


  //Create list to store all the projects
  const projectNamesList = [];
  const projectDescriptionList = [];
  const nameToID = new Map();

  //iterate through all the projects
  //if the userid is one of the users
  for (let x in project) {
    // console.log('inside project', x)
    let usersInPorject = project[x].users;
    // console.log('users inside',x,'project', usersInPorject);
    // console.log('PROJECT NAME:', project[x].projectName);

    usersInPorject.forEach((element) => {
      //if the userid is one of the users in the project
      if (element === props.username) {
        // console.log('inside user array');
        //add it to the project list
        projectNamesList.push(project[x].projectName);
        projectDescriptionList.push(project[x].description);
        nameToID.set(project[x].projectName, project[x].projectId);
      }
    });
  }

  // console.log('project  list:', projectNamesList);
  // console.log('MAP', nameToID );
  console.log('DESCRIBE', projectDescriptionList);

  const numberOfProjects = nameToID.size;



  // const { fetchProjectData } = props; // Destructure the fetchProjectData function from props
  console.log(props)

  useEffect(() => {
    // Call the fetchProjectData function when the component is mounted
    fetchProjectData();
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    color: "#5f249f",
    textsize: "large",
    justifyContent: "center",
    padding: theme.spacing(16),
    textAlign: "center",
  }));


  function GridSizing(name, id, pDesc) {

    console.log("projectName", name);
    console.log("projectID", id);

    return (
      <Grid item xl={5} ml={8} mr={2} mb={6} mt={2} color="lavender">
        <Item>
          <Typography variant="h4">{name}</Typography>
          <br></br>
          <Typography variant="b3">{pDesc}</Typography>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <CardActions>
              <Button
                // component={NavLink}
                //  to={"/ProjectSpace"}
                onClick={function () { OpenProject(name, id, pDesc) }}
              >
                Open
              </Button>

              <ShareExisting />
            </CardActions>
          </Grid>
        </Item>
      </Grid>

    );
  }

  const history = useHistory();

  const OpenProject = (theName, theID, theDesc) => {

    console.log("button clicked");

    const datas = {
      pname: theName,
      ppID: theID,

      // protoArray: {thePrototype},
      pDesc: theDesc,
    };

    const queryString = new URLSearchParams(datas).toString();
    //Pushes data to the next project space page
    history.push(`/ProjectSpace?${queryString}`);

  };

  const names = projectNamesList;
  const projectID = nameToID;
  // const prototypes = prototype;
  const description = projectDescriptionList;

  const rows = [];
  let projectToPrototype = new Map();
  let prototypeCounter = 0;
  const lists = [];
  var buttonClicked = false;

  //loop that  creates cards
  for (let i = 0; i < names.length; i++) {
    //get the id
    let pID = projectID.get(names[i]);

    rows.push(GridSizing(names[i], pID, description[i]));

  }

  return (
    <DataContext.Provider value={{ projectNamesList, projectDescriptionList, nameToID }}>
      <PersistentDrawer />
      <h1 style={{ textAlign: "center", color: "#5f249f", textsize: "large" }}>
        Your Projects
      </h1>
      <Box
        mt={10}
        mb={10}
        ml={5}
        mr={5}
        backgroundColor="#cbacec"
        alignContent={"center"}
      >
        <Grid
          direction={"row"}
          alignItems={"center"}
          container
          spacing={8}
          mt={5}
          mb={5}
        >
          {rows}
        </Grid>
      </Box>
    </DataContext.Provider>
  );
}

export default OpenExistingProject;
