
import * as React from 'react';
//import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ProjectSpaceDropdown from '../components/ProjectSpaceDropdown'
import { styled, useTheme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar from '@mui/material/AppBar';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import MenuListItems from './MenuListItems';
import UserSection from './UserSection';
import ShareExisting from '../Pages/ShareExisting';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));


//Header
const  ProjectSpaceHeader = (props) => {
    const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
    
    return (
<Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{bgcolor:'#5F249F'}} open={open}>
        <Toolbar sx={{justifyContent:'space-between'}}>
          <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            size='large'
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          
        
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }} paddingRight={5} component="div">
            DXC | Protobot     
          </Typography>
          <br></br>
          <ProjectSpaceDropdown
          component="div" 
          sx={{ flexGrow: 1,}} 
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          paddingRight={20}
          projectList ={props.projectList} 
          projectID={props.projectID}
          projectDesc={props.projectDesc}
          currentProject={props.currentProject}/>
          </Toolbar>
          <Button size='large'  color="inherit"> 

            <ShareExisting color="FFFFFF" />
            <Typography paddingLeft={1} >   Share </Typography>
          
          </Button>
                <UserSection />
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <MenuListItems/>
      </Drawer>
      <Main sx={{ flexGrow: 1 }} open={open}>
        <DrawerHeader />
      </Main>
    </Box>
  );

    };

export default ProjectSpaceHeader;


/*
<AppBar position="static">
<Toolbar variant="regular">
    <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
        <MenuIcon />
    </IconButton>
        <ProjectSpaceDropdown component="div" sx={{ flexGrow: 1 }} />
        <FilterButtons />
 </Toolbar>
</AppBar> */