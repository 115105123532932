import * as React from "react";
import Container from "@mui/material/Container";
import { Button, Card, CardActions, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import styled from "@emotion/styled";
import CardHeader from "@mui/material/CardHeader";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Popover from "@mui/material/Popover";
import { Link } from "react-router-dom";

const useStyles = styled((theme) => ({
  cardGrid: {
    padding: "10px 0",
    spacing: "10px 0",
    width: "100%",
  },
  card: {
    height: "100%",
    flexDirection: "column",
  },
  cardContent: {
    flexGrow: 1,
    justifyContent: "flex-end",
  },
  cardRow: {
    flexGrow: 3,
    flexDirection: "row",
    //justifyContent:' space-evenly', // updated this line
    alignItems: "baseline",
  },
  boxWidth: {
    width: "50%",
  },
}));

const theme = createTheme({
  typography: {
    // Tell MUI what the font-size on the html element is.
    htmlFontSize: 12,
    spacing: 12,
    color: "#5f249f",
  },
});

const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  const options = { year: "numeric", month: "long", day: "numeric" };
  return date.toLocaleDateString(undefined, options);
};

//Gets the first letter of the Last modified name
const formatAvatar = (lastModifiedName) => {
  let stringName = lastModifiedName;
  let firstLetter = stringName.substring(0, 1);

  return firstLetter.toUpperCase();
};

const ProjectItems = (props) => {
  const classes = useStyles();

  const ProjectCardHead = () => {
    return (
      <Container className={classes.cardContent}>
        <CardHeader
          action={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {formatAvatar("Oprah")}
            </Avatar>
          }
          title={
            <Typography
              gutterTop
              variant="body2"
              align="right"
              color="text.secondary"
              paddingRight={3}
            >
              Last Modified by {props.lastEditor}
            </Typography>
          }
          subheader={
            <Typography
              gutterTop
              variant="body2"
              align="right"
              color="text.secondary"
              paddingRight={3}
            >
              {formatDate(props.lastEdit)}
            </Typography>
          }
        />
      </Container>
    );
  };
  //TO DO:🟨Make font responsive and auto size for space
  const ProjectDetails = () => {
    return (
      <Grid container justifyContent="flex-start">
        <CardContent>
          <Typography gutterBottom variant="h5">
            {props.projectName} Insights
          </Typography>
        </CardContent>
      </Grid>
    );
  };

  const InsightDetails = () => {
    return (
      <Card
        sx={{
          width: 500,
          height: 150,
          backgroundColor: "#cbacec",
          p: theme.spacing(4),
          border: "solid #5f249f",
        }}
      >
        <ThemeProvider theme={theme}>
          <Typography variant="h5">{props.creator} </Typography>
          <Typography variant="h5"> {formatDate(props.creationDate)} </Typography>
        </ThemeProvider>
      </Card>
    );
  };

  // handling click event for details popover
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Container
      className={classes.cardGrid}
      maxWidth="xl"
      style={{ position: "relative", top: "50%" }}
    >
      <Grid Container spacing={4}>
        <Grid item>
          <br></br>
          <Card className={classes.card}>
            <Grid>
              <CardActions className={classes.cardRow}>
                <ProjectDetails />

                <CardActions
                  container
                  justifyContent="flex-start"
                  flexDirection="row"
                >
                  <Button size="small" color="primary" onClick={handleClick}>
                    Details
                  </Button>
                  {/* display insight details as a pop-over  */}
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <InsightDetails />
                  </Popover>

                  <Button size="small" color="primary">
                    <Link
                      to={`/openInsights/${props.projectId}`}
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      Open
                    </Link>
                  </Button>
                </CardActions>

                <ProjectCardHead />
              </CardActions>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

function OpenInsights() {
  //Opens insights page
}

function SeeInsightsDetails() {
  //
}

export default ProjectItems;
