import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";

import SendIcon from "@mui/icons-material/Send";

import { useHistory } from "react-router-dom";

import axios from "axios";

const DialogueBox = ({ open, onClose, onSubmit }) => {
  const [figmaUrl, setFigmaUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { instance } = useMsal();

  const handleChangeUrl = (event) => {
    setFigmaUrl(event.target.value);
  };

  const account = instance.getActiveAccount();
  const user_name = account.username;
  const user_folder = user_name.slice(0, -8);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const name = params.get("pname");
  const id = params.get("ppID");

  const handleSubmit = async () => {
    if (figmaUrl.trim() !== "") {
      onSubmit(figmaUrl);
    }

    try {
      setIsLoading(true);
      await axios.post("http://127.0.0.1:8000/api/generate-prototype/", {
        figma_url: figmaUrl,
        project_name: name,
        user_folder: user_folder,
      });
      history.push(`/Chat/?pname=${name}&ppID=${id}`);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title">Enter your Figma URL</DialogTitle>

      <DialogContent>
        <Typography gutterBottom>
          Please verify it is the correct URL before you submit. Figma URL can
          be extracted by clicking the frame first and copying the URL from the
          browser search bar.
        </Typography>

        <TextField
          fullWidth
          label="Figma URL"
          value={figmaUrl}
          onChange={handleChangeUrl}
          sx={{ margin: "1%", padding: "1%" }}
          InputLabelProps={{
            style: {
              color: "#000000",
            },
          }}
          InputProps={{
            style: {
              color: "#000000",
            },
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          sx={{
            color: "#000000",

            backgroundColor: "#FFFFFF",

            "&:hover": { backgroundColor: "#5F249F", color: "#FFFFFF" },
          }}
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          variant="outlined"
          sx={{
            color: "#000000",

            backgroundColor: "#FFFFFF",

            "&:hover": { backgroundColor: "#5F249F", color: "#FFFFFF" },
          }}
          onClick={handleSubmit}
          endIcon={isLoading ? <CircularProgress size={24} /> : <SendIcon />}
          disabled={isLoading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogueBox;
