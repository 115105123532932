import React, { useState, useEffect } from "react";
import PersistentDrawer from "../components/PersistentDrawer";
import DocumentsDisplay from "../components/DocumentsDisplay";
import axios from "axios";

export default function DocumentSpace(props) {
  const [data, setData] = useState({ documents: [] });
  const [documentsList, setDocumentsList] = useState([]);

  const [documentToID, setDocumentToID] = useState(new Map());
  const [documentsData, setDocumentsData] = useState([]);

  useEffect(() => {
    axios
      .get("http://127.0.0.1:8000/api/Document/")
      .then((response) => {
        const documents = response.data;
        const documentsList = [];
        const documentToID = new Map();
        const documentsData = [];

        for (const document of documents) {
          if (document.documentStatus === "Drafts") {
            documentsList.push(document.documentName);

            documentToID.set(document.documentName, document.documentLink);
            documentsData.push({
              id: document.id,
              name: document.documentName,
              link: document.documentLink,
            });
          }
        }

        setDocumentsList(documentsList);
        setDocumentToID(documentToID);
        setDocumentsData(documentsData);
      })
      .catch((err) => console.log(err));
  }, []);

  const addDocuments = (item) => {
    let items = data["items"];
    items.push(item);
    setData({ items: items });
  };

  const list = props.documentsNameList;
  const link = props.documentLink;

  console.log("HELLO", documentsData);
  console.log("documentsList (in DocumentSpace) =", list);
  console.log("documentLink (in DocumentSpace) =", link);

  return (
    <div>
      <PersistentDrawer />
      <DocumentsDisplay
        documentsNameList={documentsList}
        documentLink={documentToID}
        documentsData={documentsData}
        buttonOne="UPLOADS"
        buttonTwo="TRASH"
      />
    </div>
  );
}
