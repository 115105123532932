import * as React from 'react';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';


export default function RoleCheckBoxes() { 
    return (
        <Box
            ml={18} mt={5}
            sx={{width: 500, height: 200, backgroundColor: "white"}}>
            <Box
                ml={5} mt={9}>
                <Box>
                    <FormGroup>

                        {/* add as guest checkbox */}
                        <FormControlLabel 
                            control= {<Checkbox defaultChecked
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: "#5f249f"  }, color: "#5f249f"  }} />}  
                            label="Add as Guest"
                            InputLabelProps={{ style: { fontSize: 60 } }}>
                        </FormControlLabel>

                        {/* add as DT Prof. checkbox */}
                        <FormControlLabel 
                            control= {<Checkbox defaultChecked
                                sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: "#5f249f"  }, color: "#5f249f" }} />}  
                            label="Add as Design Thinking Professional"
                            InputLabelProps={{ style: { fontSize: 60 } }}>
                        </FormControlLabel>
                        
                    </FormGroup>
                </Box>
            </Box>
        </Box>
    )
}
