import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Card, Stack, TextField, Grid } from '@mui/material';
import Button from '@mui/material/Button'; 
import Modal from './Modal'; 


const MODAL_STYLES = { 
    position: 'fixed', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)', 
    padding: '50px', 
    zIndex: 5000
}


const theme = createTheme({
    typography: {
      // Tell MUI what the font-size on the html element is.
      htmlFontSize: 13,
    //   spacing: 8, 
      color: "white", 
    },
  });
  
  
  const GetFeedback  = (props) => {
      return (
        <Modal>
                
          <Card style={MODAL_STYLES}
            sx={{width: 700, height: 500, backgroundColor: "#cbacec", p: theme.spacing(1), border: "solid #5f249f"}}>
            <ThemeProvider theme={theme}>

                    <Typography variant="body1">The User Feedback survey will be sent to the 
                        email address entered below after it is approved by the other Design
                        Thinking professionals under this project. Please sign your name below 
                        to confirm this request. 
                    </Typography>


                    <Grid container spacing={2} mt={5} backgroundColorcolor="#FFFFFF">
                        <Grid item xs={6} md={8}>
                            <TextField
                                helperText="Enter Feedback requestee email here"
                                id="demo-helper-text-aligned"
                                // label="Enter Feedback requestee email here"
                            />
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <TextField
                                helperText="Sign your name here"
                                id="demo-helper-text-aligned"
                                // label="Sign your name here"
                            />
                        </Grid>
                    </Grid>

                    <Stack ml={40} direction="row" spacing={2}>
                        <Button onClick={props.onClose} justifyContent="flex-end" color="secondary">Close</Button> 
                        <Button color="secondary">Submit</Button>
                    </Stack>
                    
            </ThemeProvider>
          
          </Card>
  
        </Modal>
  )
}

export default GetFeedback