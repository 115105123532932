import * as React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import {
  Button,
  Card,
  CardActions,
  Typography,
  CardMedia,
  CardContent,
  Container,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
function DocumentsNav(status, id) {
  if (status === "DRAFTS") {
    axios
      .put(`http://127.0.0.1:8000/api/document/${id}/move_to_drafts/`)
      .then((response) => {
        console.log(response.data.message);
        // Optionally, you can update the documentsList state here to reflect the changes on the client side
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  } else if (status === "TRASH") {
    axios
      .put(`http://127.0.0.1:8000/api/document/${id}/move_to_trash/`)
      .then((response) => {
        console.log(response.data.message);
        // Optionally, you can update the documentsList state here to reflect the changes on the client side
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  } else if (status === "UPLOADS") {
    axios
      .put(`http://127.0.0.1:8000/api/document/${id}/restore/`)
      .then((response) => {
        console.log(response.data.message);
        // Optionally, you can update the documentsList state here to reflect the changes on the client side
        window.location.reload();
      })
      .catch((error) => {
        console.error(error);
      });
  }
}
const handleDownload = async (blobLink, fileName) => {
  try {
    console.log(blobLink, fileName);
    // Fetch the SAS token from your server or any secure location
    const responseBackend = await fetch("http://127.0.0.1:8000/download/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ blobLink, fileName }),
    });

    if (!responseBackend.ok) {
      throw new Error("Failed to generate SAS token.");
    }

    const data = await responseBackend.json();
    const sasToken = data.sasToken;

    // Combine the Blob URL with the SAS token
    const signedBlobUrl = blobLink + sasToken;

    console.log(sasToken);
    // Fetch the blob data using the signed URL
    const response = await fetch(signedBlobUrl);

    if (!response.ok) {
      throw new Error("Network response was not ok.");
    }

    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
export default function DocumentsDisplay(props) {
  // const docs = props.documents;
  // const links = props.link;

  // let x = links.get(docs[0]);
  // console.log("x =", x);
  const documentsData = props.documentsData;
  const docs = props.documentsNameList;
  const links = props.documentLink;
  const id = props.documentsIdList;

  console.log("documentsData (in DocumentsDisplay) =", id);
  console.log("documentsData (in DocumentsDisplay) =", documentsData);
  console.log("documentsList (in DocumentsDisplay) =", docs);
  console.log("documentLink (in DocumentDisplay) =", links);

  return (
    <Container maxWidth="xl" style={{ position: "relative", top: "50%" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {documentsData.map((document, index) => (
            <Grid xs={2} sm={2} md={3} key={index}>
              <Card sx={{ maxWidth: 345 }}>
                <CardMedia
                  sx={{ height: 140 }}
                  image="/static/images/cards/contemplative-reptile.jpg"
                  title="green iguana"
                />

                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {/* File name {index+1}  */}

                    {document.name}
                  </Typography>

                  <Typography gutterBottom variant="h5" component="div">
                    {/* File name {index+1}  */}
                    {document.id}
                  </Typography>

                  <CardActions>
                    <Button
                      size="small"
                      onClick={() =>
                        handleDownload(document.link, document.name)
                      }
                    >
                      Download
                    </Button>

                    <Button
                      size="small"
                      onClick={() => DocumentsNav(props.buttonOne, document.id)}
                    >
                      Move to {props.buttonOne}
                    </Button>
                    <Button
                      size="small"
                      onClick={() => DocumentsNav(props.buttonTwo, document.id)}
                    >
                      Move to {props.buttonTwo}
                    </Button>
                  </CardActions>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}
