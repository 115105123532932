import * as React from 'react';
import {Button} from '@mui/material';
import ButtonGroup from '@mui/material/ButtonGroup';
import RefreshIcon from '@mui/icons-material/Refresh';
import GetFeedback from './GetFeedback';
/* 
get feedback displays when the request for feedback has not been sent

pending displays when the feedbackform has not been approved

awaiting results displays when it has been sent to the client but not filled out

view feedback displays when filled out by client

*/

//retrieve THAT project, get the Prototype version, get Feedback status value from backend

//variable for the projects prototype feedback status
 

const PrototypeFeedbackButton  = (props) => {

let feedbackStatus = 'not approved'

// const [buttonText, setButtonText] = React.useState(<Button size='small' color='primary' onClick={RequestFeedback}>  Get feedback </Button>);
const [buttonText, setButtonText] = React.useState(false);


return (
   < ButtonGroup variant='text' > 
            {/* {buttonText}  */}
            <Button size='small' color='primary' onClick={props.onShowFeedback}>  Get feedback </Button> 
            <RefreshIcon onClick={changeButtonText}/>
   </ButtonGroup> 
);


function changeButtonText () {

    
    if (feedbackStatus === 'ready to view') {
        setButtonText(<Button size='small' color='primary' onClick={ViewFeedback}> View Feedback </Button>);
    
    } else if (feedbackStatus === 'not approved') {
        setButtonText(<Button size='small' color='primary' disabled > Awaiting approval </Button>);
    

    } else if ( feedbackStatus === 'not completed by client') {
        setButtonText(<Button size='small' color='primary' disabled> Awaiting results </Button>);
    

    } else if (feedbackStatus === 'get feedback') {
        setButtonText(<Button size='small' color='primary' onClick={RequestFeedback}> Get Feedback </Button>);
    
    }
}

}


function RequestFeedback () {
    //Opens feedback request modal
    <GetFeedback />
}

function ViewFeedback() {
    //renders the feedback recieved by the client
}


    export default PrototypeFeedbackButton;



    //when the button is clicked, get the ID

    //pass it into the onclick function

    //get the object that has the ID for its button value

    //get the feedback status of that ID

    //