import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import MailIcon from '@mui/icons-material/Mail';
import FolderIcon from '@mui/icons-material/Folder';
import DraftsIcon from '@mui/icons-material/Drafts';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import { NavLink } from 'react-router-dom';


const MenuListItems = () => {

    return (
        <>
            <List>
            {['Home', 'Inbox', 'Documents'].map((text, index) => (
                <ListItem key={text} disablePadding component={NavLink} to= {index === 0 ? '/' :(index === 1 ? 'Inbox': 'Documents')} >
                <ListItemButton>
                    <ListItemIcon>
                    {index === 0 ? <HomeIcon/> : 
                    (index === 1 ? <MailIcon />: <FolderIcon/>)}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                </ListItemButton>
                </ListItem>
            ))}
            </List>
            <Divider />
            <List>
            {['Drafts', 'Trash'].map((text, index) => (
                <ListItem key={text} disablePadding component={NavLink} to={index % 2 === 0 ? 'Drafts' : 'Trash'}>
                <ListItemButton>
                    <ListItemIcon>
                    {index % 2 === 0 ? <DraftsIcon /> : <RestoreFromTrashIcon />}
                    </ListItemIcon>
                    <ListItemText primary={text} />
                </ListItemButton>
                </ListItem>
            ))}
            </List>

        </>
    );
};

export default MenuListItems;