import React from 'react';
import Chat from './Chat';

export default function CreatePrototype(props) {

  return (
   <Chat />
  )
}

