import * as React from 'react';
import Container from '@mui/material/Container';
import { Button, Card, CardActions, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import styled from '@emotion/styled';
import CardHeader from '@mui/material/CardHeader';
import Avatar from '@mui/material/Avatar';
import { red } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Popover from '@mui/material/Popover';
import PrototypeFeedbackButton from './PrototypeFeedbackButton';
import GetFeedback from './GetFeedback';


const useStyles = styled ((theme) => ({
    cardGrid:{
        padding: '10px 0',
        spacing: '10px 0'
    },
    card:{
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    cardContent:{
        flexGrow:1,
        justifyContent:"flex-end"
    },
    cardRow:{
        flexGrow:3,
        flexDirection: 'row',
       // justifyContent:"space-evenly",
        alignItems:"baseline"
    },
    boxWidth:{
        width:'50%'
    }

}));


const theme = createTheme({
    typography: {
        // Tell MUI what the font-size on the html element is.
        htmlFontSize: 12,
        spacing: 12, 
        color: '#5f249f', 
    },
    });

const ProjectPrototypeItem  = (props) => {
    const classes = useStyles();

    //Formats the date from numbers into  wording format
    const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString(undefined, options);
      };

      //Gets the first letter of the Last modified name
      const   formatAvatar = (lastModifiedName) => {
        let stringName = lastModifiedName;
        let firstLetter = stringName.substring(0,1);

        return firstLetter.toUpperCase();
      }



    const  ProjectCardHead  = () => {

       
          
        return (
            <Container className={classes.cardContent} >
                <CardHeader
                    action={
                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                            {formatAvatar(props.lastModifiedBy)}
                        </Avatar>
                    }

                    title= { 
                        <Typography gutterTop variant='body2' align='right' color="text.secondary" paddingRight={3}>
                            Last Modified by  {props.lastModifiedBy}
                        </Typography>
                    }
    
                    subheader= {
                            <Typography gutterTop variant='body2' align='right' color="text.secondary" paddingRight={3}>
                                {formatDate(props.lastModified)}
                            </Typography>
                    }
                />
                
           </Container>
        );
    }


    const  ProjectDetails = () => {
        return ( <Grid  container justifyContent="flex-start" >
            <CardContent>
                <Typography gutterBottom variant='h5'>
                {props.prototypeName}
                </Typography>
            </CardContent>
        
        </Grid>);
    }
            
              
    const PrototypeDetails  = () => {
        return (
            <Card
                sx={{width: 500, height: 150, backgroundColor: "#cbacec", p: theme.spacing(4), border: "solid #5f249f"}}>
            
                <ThemeProvider theme={theme}>
                    <Typography variant="h5">Creator:{props.createdBy}</Typography>
                    <Typography variant="h5">Version number: {props.versionNumber}</Typography>
                </ThemeProvider>
            
            </Card>
        ) 
    } 

    // handling click event for details popover 
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => { 
        setAnchorEl(event.currentTarget); 
    }

    const handleClose = () => { 
        setAnchorEl(null); 
    }

    const open = Boolean(anchorEl); 
    const id = open ? 'simple-popover' : undefined; 



    {/* feedback modal click event handlers  */}
    const [getFeedback, setFeedbackIsShown] = React.useState(false); 

    const showFeedback = () => { 
        setFeedbackIsShown(true); 
    }; 

    const hideFeedback = () => { 
        setFeedbackIsShown(false); 
    }; 


    return (
       
        <Container className={classes.cardGrid} maxWidth="xl" style={{position: 'relative', top: '50%'}}>
        <Grid Container spacing={4}>
            <Grid item>
            <br></br>
                <Card className={classes.card}>
                    <Grid >
                        <CardActions className={classes.cardRow}> 
                        
                             
                                < ProjectDetails />
                            
                                <CardActions  container justifyContent="flex-start"  flexDirection= 'row'>
                                
                               
                                    <Button size='small' color='primary' onClick={handleClick}> Details</Button>

                                    {/* display prototype details as a pop-over  */}
                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom', 
                                            horizontal: 'left', 
                                        }}>
                                            
                                        <PrototypeDetails />

                                    </Popover>


                           
                                    <Button 
                                        size='small' 
                                        color='primary' 
                                        onClick={OpenPrototype}
                                        href="https://www.youtube.com/watch?v=xvFZjo5PgG0" 
                                        target="_blank" 
                                        rel="noopener noreferrer"
                                        id='33'> 
                                    View
                                    </Button>

                                    
                                    {getFeedback && <GetFeedback onClose={hideFeedback}/>}

                                    < PrototypeFeedbackButton onShowFeedback={showFeedback}/>  
                        
                                </CardActions>

                                
                                <ProjectCardHead />
                            
           
                        </CardActions>
                        
                        
                    </Grid>
                   
                </Card>
                
            </Grid>
        </Grid>

</Container>

    );
}


function OpenDetails () {
    //Opens Details component
    //Done
}

function OpenPrototype () {
    //Opens prototype
    alert("Launching Application  \n \n Click the view button again if you are not redirected");
}




export default ProjectPrototypeItem;