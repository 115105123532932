import React, { useState, useEffect } from "react";
import { Box, Grid, TextField, Button, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import ChatMessage from "../Chat/ChatMessage";
import { BlobServiceClient } from "@azure/storage-blob";
import { useLocation } from "react-router-dom";

function HtmlPreview({ htmlContent }) {
  return <Box dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}

const Chat = (props) => {
  const [messages, setMessages] = useState([
    {
      sender: "Protobot",
      text: "Hi, I am Protobot your AI prototype creator! Let me know what you think or would like to change about the prototype!",
    },
  ]);
  const [htmlContent, setHtmlContent] = useState("");
  const [originalHtmlContent, setOriginalHtmlContent] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();

  const account = instance.getActiveAccount();
  const user_name = account.username;
  const user_folder = user_name.slice(0, -8);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const projectName = params.get("pname");

  useEffect(() => {
    const blobSAS =
      "sp=r&st=2023-08-04T02:01:07Z&se=2023-08-04T10:01:07Z&spr=https&sv=2022-11-02&sr=c&sig=a6JEMg%2BsSZSO67RBCPBHwJdZ3RP1BhE7hxOiwgk7foY%3D";

    const blobSasUrl = `https://dxcproject2storage.blob.core.windows.net/$web/${user_folder}/${projectName}/index.html?${blobSAS}`;

    fetch(blobSasUrl)
      .then((response) => response.text())
      .then((data) => {
        setHtmlContent(data);
        setOriginalHtmlContent(data); // Save original
      })
      .catch((error) => {
        console.error(`Error fetching the file: ${error}`);
      });
  }, []);

  const handleSendMessage = () => {
    if (message.length !== 0) {
      setLoading(true); // Set loading to true when sending a message

      // Add user message to chat immediately
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "You", text: message },
      ]);

      axios
        .post("http://127.0.0.1:8000/api/chat/", {
          message: message,
          html_content: htmlContent,
        })
        .then((response) => {
          if (response.data.error) {
            console.error(`Error from server: ${response.data.error}`);
          } else {
            setMessages((prevMessages) => [
              ...prevMessages,
              { sender: "Protobot", text: response.data.message },
            ]);
            if (response.data.message) {
              setHtmlContent(response.data.message);
            }
            setLoading(false); // Set loading to false once the message has been sent
          }
        })
        .catch((error) => {
          console.error(`There was an error sending the message: ${error}`);
          setLoading(false); // Set loading to false if there was an error
        });

      setMessage("");
    }
  };

  const handleRevert = (message) => {
    if (message.sender === "Protobot") {
      if (
        message.text ===
        "Hi, I am Protobot your AI prototype creator! Let me know what you think or would like to change about the prototype!"
      ) {
        setHtmlContent(originalHtmlContent); // Set to original code if initial message is clicked
      } else {
        setHtmlContent(message.text);
      }
    }
  };

  const handleDeploy = () => {
    const createFolder = async () => {
      const blobSAS =
        "?sv=2022-11-02&ss=bf&srt=sco&sp=wlactfx&se=2023-08-31T02:37:11Z&st=2023-07-12T18:37:11Z&spr=https&sig=7nf%2BjwLlFir3yGp7CJ9gWInQ4ivS0%2BaUG2%2FLGTLXRYg%3D";
      const blobSasUrl = `https://dxcproject2storage.blob.core.windows.net/$web/${blobSAS}`;
      const blobServiceClient = new BlobServiceClient(blobSasUrl);
      //keith.mitchell2@dxc.com
      const containerClient = blobServiceClient.getContainerClient(user_folder);
      const folderName = projectName;
      const fileName = "index.html";
      const blobName = `${folderName}/${fileName}`;
      const content = htmlContent;
      const blobClient = containerClient.getBlockBlobClient(blobName);
      const options = { blobHTTPHeaders: { blobContentType: "text/html" } };
      const response = await blobClient.upload(
        content,
        content.length,
        options
      );
    };
    createFolder().catch((error) => {
      console.error(`There was an error deploying: ${error}`);
    });
  };

  return (
    <div>
      {loading ? <p>Generating code ...</p> : null}
      <Grid container spacing={2}>
        <Grid item xs={8}>
          {/* Adjust the height of the file preview section */}
          <Box
            maxHeight="70vh" // Set the maximum height of the file preview section
            overflow="auto" // Add scrollbars when the content exceeds the height
            pr={2} // Add padding to the right side for separation
          >
            <HtmlPreview htmlContent={htmlContent} />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6">Chat with Protobot</Typography>
          <Box
            display="flex"
            flexDirection="column"
            height="70vh" // Set the height of the chat section
            justifyContent="space-between"
            pl={2} // Add padding to the left side for separation
          >
            <Box overflow="auto" maxHeight="90%">
              {messages.map((message, index) => (
                <ChatMessage
                  key={index}
                  message={message}
                  onRevert={handleRevert}
                />
              ))}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={2}
            >
              <TextField
                variant="outlined"
                fullWidth
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSendMessage}
                >
                  Send
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleDeploy}
                >
                  Deploy
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Chat;
