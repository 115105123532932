import React, { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";

const SignInButton = () => {
  const { instance } = useMsal();

  const handleSignIn = async () => {
    try {
      const loginResponse = await instance.loginPopup({
        scopes: ["user.read"],
      });

      // Access token and account information
      const accessToken = loginResponse.accessToken;
      const account = loginResponse.account;

      // Use the access token or account as needed
      console.log("Access Token:", accessToken);
      console.log("Account:", account);

      // Send the user data to the Django backend
      const user = {
        email: account.username,
        firstName: account.name.split(" ")[1],
        lastName: account.name.split(" ")[0].trim().slice(0, -1),

        // You may need to extract more information from the MSAL response as needed.
      };

      // Send the user data to the Django backend
      await fetch("http://127.0.0.1:8000/api/createUser/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Button color="inherit" onClick={handleSignIn}>
      Sign In
    </Button>
  );
};

export default SignInButton;
