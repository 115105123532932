import React from 'react'
import ProjectItems from '../components/ProjectItems';
import ProjectSpaceHeader from '../components/ProjectSpaceHeader';
import ProjectPrototypeItem from '../components/ProjectPrototypeItem';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import axios from 'axios'
import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import DialogueBox from '../components/DialogueBox';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "#ffffff",
  border: "2px solid #5F249F",
  boxShadow: 24,
  p: 4,
};


//we have the name, the id and trhe desc, 

//the prototytpes are filtered and rendered here


export default function ProjectSpace(props) {

  const [project, setProject] = useState([]);

  const [openForm, setOpenForm] = useState(false);
  const handleOpenForm = () => setOpenForm(true);
  const handleCloseForm = () => setOpenForm(false);

  const handleDialogueSubmit = (figmaUrl) => {
    console.log('Submitted Figma URL:', figmaUrl);
    // Add your logic to handle the Figma URL here.
  };

  useEffect(() => {
    fetchProjectData();
    axios
      .get("http://127.0.0.1:8000/api/project/")
      .then((response) => {
        setProject(response.data);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log("projects:", project);
  const fetchProjectData = () => {
    axios
      .get("http://127.0.0.1:8000/api/project/")
      .then((response) => {
        setProject(response.data);
      })
      .catch((err) => console.log(err));
  };

  //Create list to store all the projects
  const projectNamesList = [];
  const projectDescriptionList = [];
  const nameToID = new Map();
  let creator =''
  let createdTime =''
  //iterate through all the projects
  //if the userid is one of the users
  for (let x in project) {
    // console.log('inside project', x)
    let usersInPorject = project[x].users;
    creator = project[x].users[0];
    createdTime = project[x].createdAt;
    // console.log('users inside',x,'project', usersInPorject);
    // console.log('PROJECT NAME:', project[x].projectName);


    usersInPorject.forEach((element) => {
      //if the userid is one of the users in the project
      if (element === props.username) {
        // console.log('inside user array');
        //add it to the project list
        projectNamesList.push(project[x].projectName);
        projectDescriptionList.push(project[x].description);
        nameToID.set(project[x].projectName, project[x].projectId);
      }
    });
  }


  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const name = params.get("pname");
  const id = params.get("ppID");
  const desc = params.get("pDesc");

  const [detailsShown, setDetailsShown] = useState(false);

  const showProtDetails = () => {
    setDetailsShown(true);
  };
  const hideProtDetails = () => {
    setDetailsShown(false);
  };
  const [prototype, setPrototype] = useState([]);
  useEffect(() => {
    axios
      .get("http://127.0.0.1:8000/api/prototype/")
      .then((response) => {
        setPrototype(response.data);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log("prototype:", prototype);
  console.log("INSIDE PROJECT SPACE", name);
  //map the insight data to the insight component
  console.log("PROJECT NAME", name);
  console.log("PROJECT ID", id);

  console.log("PROTOTYPES", prototype);

  const protoList = [];

  for (let index = 0; index < prototype.length; index++) {
    //compare the id and the project name value in the prototype
    console.log("ID", id);
    console.log("ID MATCH", prototype[index].projectName);

    if ( id == prototype[index].projectName) {
      // if they are the same add the prototype to the list
      console.log("Prototype found");
      protoList.push(prototype[index]);

    }
  }

  console.log("PROTOLIST", protoList);

  function createPrototypes(
    cB,
    fS,
    lastM,
    lastMB,
    link,
    pN,
    protoName,
    versionNum
  ) {

    return (
      <ProjectPrototypeItem
        createdBy={cB}
        feedbackStatus={fS}
        lastModifiedBy={lastM}
        lastModified={lastMB}
        link={link}
        projectName={pN}
        prototypeName={protoName}
        versionNumber={versionNum}
      />
    );
  }
  const projectFiles = [
    <ProjectItems
      projectName={name}
      projectId={id}
      // lastEdit=
      // lastEditor=
      creator={creator}
      creationDate={createdTime}
    />,
  ];

  if (protoList.length !== 0) {
    console.log("PROTOTYPES INSIDE A LIST", protoList);

    for (let index = 0; index < protoList.length; index++) {
      //for each prototype map the data to a prototype component
      projectFiles.push(
        createPrototypes(
          protoList[index].createdBy,
          protoList[index].feedback_status,
          protoList[index].lastModifiedBy,
          protoList[index].lastModified,
          protoList[index].link,
          protoList[index].projectName,
          protoList[index].prototypeName,
          protoList[index].versionNumber
        )
      );
      console.log("Prototype INDEX NUMBER", index);
    }
  }


  return (
    <div>
      <ProjectSpaceHeader
        projectList={projectNamesList}
        projectID={nameToID}
        projectDesc={projectDescriptionList}
        currentProject={name} />

      <Box sx={{ justifyContent: 'center' }}>
        <Typography> {desc}</Typography>
      </Box>

      <br></br>
      {projectFiles}
      <br></br>      

      <Box
        style={{ position: "absolute", bottom: "100", right: "0" }}
        padding={4}
      >
        <ButtonGroup
          sx={{
            ".MuiButtonGroup-grouped:not(:last-of-type)": {
              borderColor: "#D9D9D6",
            },
          }}
          variant="contained"
          size="large"
          aria-label="outlined primary button group"
          pa
        >
          <Button onClick={handleOpenForm}>
            <AddCircleIcon />
            <Typography paddingLeft={1}>
              Create prototype
            </Typography>
          </Button>
        </ButtonGroup>
      </Box>

      <DialogueBox open={openForm} onClose={handleCloseForm} onSubmit={handleDialogueSubmit} />

    </div>
  );

}
