import React, { useState, useEffect } from "react";
import axios from "axios";
import PersistentDrawer from "../components/PersistentDrawer";
import LandingSection from "../components/LandingSection";

const HomePage = ({ handleUserData }) => {
  console.log("lOGGED in !!");
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [username, setUserName] = useState("");
  const [documentsList, setDocumentsList] = useState([]);
  const [draftsList, setDraftsList] = useState([]);
  const [trashList, setTrashList] = useState([]);
  const [documentToID, setDocumentToID] = useState(new Map());

  const getMessage = async () => {
    try {
      const response = await axios.get("https://protobot-backend.azurewebsites.net/test/");
      setMessage(response.data.message);
    } catch (error) {
      console.error(error);
      setMessage("Error Communicating with Backend");
    }
  };

  useEffect(() => {
    handleUserData();
    // Fetch other data and set state here
    // Get user data
    axios
      .get("http://127.0.0.1:8000/api/user/")
      .then((response) => {
        setUserName(response.data.username);
      })
      .catch((err) => console.log(err));

    // Fetch documents data
    axios
      .get("http://127.0.0.1:8000/api/Document/")
      .then((response) => {
        const documentsList = [];
        const draftsList = [];
        const trashList = [];
        const documentToID = new Map();

        for (let x in response.data) {
          let document = response.data[x].documentName;
          let docLink = response.data[x].documentLink;
          let docStatus = response.data[x].documentStatus;

          if (docStatus === "uploads") {
            documentsList.push(document);
            documentToID.set(document, docLink);
          } else if (docStatus === "drafts") {
            draftsList.push(document);
          } else {
            trashList.push(document);
          }
        }

        setDocumentsList(documentsList);
        setDraftsList(draftsList);
        setTrashList(trashList);
        setDocumentToID(documentToID);
      })
      .catch((err) => console.log(err));
  }, []);
console.log('proppers', )
  return (
    <>
      <center>
        <div className="Backend" style={{ marginBottom: "2%" }}>
          <h2>
          Welcome to the ProtoBot Web Application
          </h2>
          <div>
            <button onClick={getMessage}>
              Click Here to get Message from Backend!
            </button>
            <div>{message}</div>
          </div>
        </div>
      </center>
      <LandingSection/>
    </>
  );
};

export default HomePage;
