import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton'; 
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button'; 
import RoleCheckBoxes_Existing from '../components/RoleCheckBoxes-Existing'
import { Modal } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import FolderSharedIcon from '@mui/icons-material/FolderShared';

export default function ShareExisting() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

  return (
    <><IconButton color="inherit" onClick={handleOpen}>
            <FolderSharedIcon color=''/>
             </IconButton>
        
        <Modal
            open={open}
            //onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
    
    <Box 
        justifyContent="center"
        alignItems="center"
        border={"solid #5f249f"}
        ml={42} mt={15} mb={15} mr={15}
        sx={{width: 800, height: 500, backgroundColor: "#cbacec"}}>


        <IconButton aria-label="delete" onClick={handleClose}>
            <CloseTwoToneIcon />
        </IconButton>

        <Box
            ml={18} mt={5}
            sx={{width: 500, height: 55, backgroundColor: "white"}}>
            <TextField fullWidth label="Insert Email" color="secondary" id="fullWidth" />
        </Box>

        
        {RoleCheckBoxes_Existing()}


        <Box 
            sx={{width: 100, height: 8}}
            ml={85} mt={5}>

            <Button variant="contained" endIcon={<SendIcon />}>
                Share
            </Button>

        </Box>

    </Box>

         </Modal>
    </>
  )
}

