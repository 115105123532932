export const msalConfig = {
    auth: {
        clientId: '03fcf038-a5f0-49d6-ac4b-6e3731ecdedd', 
        authority: 'https://login.microsoftonline.com/93f33571-550f-43cf-b09f-cd331338d086', 
        redirectUri: window.location.href   
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

