import React, { useState } from 'react';    
import { Button, ButtonGroup, Slider, TextField, Dialog, DialogTitle, DialogContent } from '@mui/material';  
import { Container, Stack, Paper, Typography } from '@mui/material';  
import axios from 'axios';  
  
// SurveyForm component that accepts a list of questions as a prop    
const SurveyForm = ({ questions, open, handleClose }) => {  

  const getDefaultSliderValues = () => {  
    const defaultSliderValues = {};  
    questions.forEach((question, index) => {  
      if (question.answerType === 'slider') {  
        defaultSliderValues[index] = { rating: '10' }; 
      }  
    });  
    return defaultSliderValues;  
  };  
  
  const [answers, setAnswers] = useState(getDefaultSliderValues()); 

  const handleInputChange = (index, value, type) => {  
    setAnswers({  
      ...answers,  
      [index]: {  
        ...answers[index],  
        [type]: value,  
      },  
    });  
  }; 

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(answers);
    // Send a POST request to the Django view
    try {
      await axios.post('http://127.0.0.1:8000/api/submitSurvey/', answers);
      alert("Form submitted!");
    } catch (error) {
    console.error(error);
    alert("An error occurred while submitting the form.")
    }
  };

    // Configuration for different answer inputs based on answerType
    const renderAnswerInput = (answerType, index) => {
        switch (answerType) {
            case 'yesNo' :
                return (
                    <ButtonGroup variant="contained" >  
                        <Button color="primary" style={{ backgroundColor: '#5f249f' }}onClick={() => handleInputChange(index, 'Yes', 'response')}>
                          Yes
                        </Button> 

                        <Button color="primary" style={{ backgroundColor: '#c2acda' }} onClick={() => handleInputChange(index, 'No', 'response')}>
                          No
                        </Button>
                      </ButtonGroup>    
                );
            case 'slider':
                return (
                    <>
                        <Slider 
                          defaultValue={10}
                          valueLabelDisplay="auto"
                          step={1}
                          marks
                          min={1}
                          max={10}
                          color="primary"  
                          style={{ color: '#5f249f' }}
                          onChangeCommitted={(e, value) => handleInputChange(index, value, 'rating')}
                        />
                        <TextField
                          multiline
                          fullWidth
                          rows={3}
                          onChange={(e) => handleInputChange(index, e.target.value, 'response')}
                        />
                    </>
                );
                default:
                    return(
                      <TextField
                        multiline
                        fullWidth
                        rows={3}
                        onChange={(e) => handleInputChange(index, e.target.value, 'response')}
                      />
                    );
        }
    };

    // Render a survey box containing the question and answer input  
    const renderSurveyBox = (question, index) => {  
      return (  
        <Container maxWidth="sm"key={index}>
            <Paper elevation={3} style={{ padding: '1rem', margin: '1rem 0', textAlign: 'center', }}>  
              <Typography variant="h6" style={{ marginBottom: '1rem' }}>{question.text}</Typography>  
              {renderAnswerInput(question.answerType, index)}   
            </Paper> 
        </Container>  
      );  
    };  
  
  // Automatically render the survey boxes as needed
  return (    
    <Dialog open={open} onClose={handleClose}>  
      <DialogTitle>Survey</DialogTitle>  
      <DialogContent>  
        <form onSubmit={handleSubmit}>   
          <Stack     
            direction="column"  
            justifyContent="center"  
            alignItems="center"  
            spacing={1}>  
          {questions.map((question, index) => renderSurveyBox(question, index))}    
          <Button variant="contained" color="primary" style={{ backgroundColor: '#5f249f' }} type="submit">Submit</Button>  
          </Stack>  
        </form>    
      </DialogContent>  
    </Dialog>  
  );    
}; 

export default SurveyForm;  