import { Fragment } from 'react'; 
import ReactDOM from 'react-dom'; 


const OVERLAY_STYLES = { 
    position: 'absolute', 
    top: 0, 
    left: 0, 
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, .7)', 
    zIndex: 1000
}

const MODAL_STYLES = { 
    position: 'absolute', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)', 
    // padding: '50px', 
    zIndex: 1000
}


const Backdrop = (props) => { 
    return <div style={OVERLAY_STYLES} />
}; 

const ModalOverlay = (props) => { 
    return ( 
        <div style={MODAL_STYLES}>
            {props.children}
        </div>
    )
}; 


const portalElement = document.getElementById('portal'); 


const Modal = (props) => { 

    return <Fragment>
        {ReactDOM.createPortal(<Backdrop />, portalElement)}
        {ReactDOM.createPortal(<ModalOverlay>{props.children}</ModalOverlay>, portalElement)}
    </Fragment>

}

export default Modal; 