import * as React from "react";
import { useEffect, useState} from "react";
import axios from "axios";
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";

const CreateProject = (props) => {
  const location = useLocation();
  const { projectName, description, selectedRole } = location.state || {};

  const [project, setProject] = useState([]);
  useEffect(() => {
    fetchProjectData();
  }, []);

  const fetchProjectData = () => {
    axios
      .get("http://127.0.0.1:8000/api/project/")
      .then((response) => {
        setProject(response.data);
        console.log("--///////////////////////////////////FRONTEND PULL"+response);
      })
      .catch((err) => console.log(err));
  };

  const history = useHistory();
  const handleOpenProject = () => {
    const nameToID = new Map();
    const projectNamesList = [];
    const projectDescriptionList = [];

    project.forEach((proj) => {
      nameToID.set(proj.projectName, proj.projectId);
      if (proj.users.includes(props.username)) {
        projectNamesList.push(proj.projectName);
        projectDescriptionList.push(proj.description);
      }
    });

    // After the loop, navigate to the ProjectSpace page
    if (projectNamesList.length > 0) {
      const datas = {
        pname: projectNamesList[projectNamesList.length - 1],
        ppID: nameToID.get(projectNamesList[projectNamesList.length - 1]),
        pDesc: projectDescriptionList[projectDescriptionList.length - 1],
      };
      console.log("///////////////////////////"+datas.pname+datas.ppID);
      const queryString = new URLSearchParams(datas).toString();
      history.push(`/ProjectSpace?${queryString}`);
    }
  };

  useEffect(() => {
    handleOpenProject();
  }, [project, props.username]);

};

export default CreateProject;
