import React from "react";
import { Card, CardContent, Typography, Tooltip } from "@mui/material";

const ChatMessage = ({ message, onRevert }) => {
  const messageCard = (
    <Card
      variant="outlined"
      sx={{
        my: 1,
        border: message.sender === "Protobot" ? "2px solid #3f51b5" : "none",
        cursor: message.sender === "Protobot" ? "pointer" : "default",
      }}
      onClick={() => message.sender === "Protobot" && onRevert(message)}
    >
      <CardContent>
        <Typography variant="subtitle2">{message.sender}</Typography>
        <Typography variant="body2">{message.text}</Typography>
      </CardContent>
    </Card>
  );

  return message.sender === "Protobot" ? (
    <Tooltip title="Click to see this preview">{messageCard}</Tooltip>
  ) : (
    messageCard
  );
};

export default ChatMessage;
