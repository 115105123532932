import * as React from 'react';
import { useContext } from 'react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useIsAuthenticated } from '@azure/msal-react';
import { useMsal } from "@azure/msal-react";
import UserContext from '../ContextAPI/Context';

const UserSection = () => {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openDialogue, setOpenDialogue] = React.useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();
  const name = useContext(UserContext);

  const handleDialogueOpen = () => {
    setOpenDialogue(true);
  };

  const handleDialogueClose = () => {
    setOpenDialogue(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleSignout = () => {
    instance.logoutRedirect();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {auth && (
        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
            <p style={{fontSize:"14px", paddingTop: "5px"}}> {name}</p>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <FormGroup>
                <div>
                  {isAuthenticated?
                  <Button
                    sx={{backgroundColor:"#5F249F", "&:hover":{backgroundColor:"#5F249F"}}}
                    variant="contained"
                    onClick={handleDialogueOpen}
                  >
                    Sign Out
                  </Button>
                  :null
                  }
                  <Dialog
                    open={openDialogue}
                    onClose={handleDialogueClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Sign out of your account?"}
                    </DialogTitle>
                    <DialogActions>
                      <Button sx={{color:"#5F249F", backgroundColor:"#FFFFFF", "&:hover":{backgroundColor:"#5F249F", color:"#FFFFFF"}}} onClick={handleSignout} autoFocus>
                        Sign out
                      </Button>
                      <Button sx={{color:"#5F249F", backgroundColor:"#FFFFFF", "&:hover":{backgroundColor:"#5F249F", color:"#FFFFFF"}}} onClick={handleDialogueClose}>
                        Cancel
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </FormGroup>
            </MenuItem>
          </Menu>
        </div>
      )}
    </>
  );
};

export default UserSection;
