import React, { useState } from "react";
//import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Button from "@mui/material/Button";
import { TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import InsightsResponse from "../components/InsightsResponse";
import { Stack } from "@mui/material";
import axios from "axios";
import {
  AnonymousCredential,
  newPipeline,
  BlobServiceClient,
} from "@azure/storage-blob";
import styled from "@emotion/styled";

const useStyles = styled((theme) => ({
  root: {
    position: "fixed",
    bottom: 0,
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#f0f0f0", // Set your desired background color
    padding: theme.spacing(2),
  },
}));

const AddInsightsGroup = ({ projectId }) => {
  const classes = useStyles();
  const fileInputRef = React.useRef(null);
  const [inputText, setInputText] = useState("");

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };


  async function uploadFile() {
    const fileInput = fileInputRef.current;
    const file = fileInput.files[0];

    if (!file) {
      alert("Please select a file to upload.");
      const isButtonDisabled = true;
      return;
    }
    const fileSizeLimit = 10 * 1024 * 1024; // 5 MB in bytes
    if (file.size > fileSizeLimit) {
      alert("File size exceeds the limit of 10 MB.");
      return;
    }
    try {
      //const response = await blockBlobClient.uploadBrowserData(file);
      alert("File uploaded successfully!");
      const formData = new FormData();
      formData.append("file", file);

      const response = await axios.post(
        "http://127.0.0.1:8000/upload/2/",
        formData
      );
      if (response.data.message) {
        alert(response.data.message); // File uploaded successfully message
      } else {
        alert("Error uploading file"); // Handle potential error response
      }
    } catch (error) {
      alert("Error uploading file: " + error.message);
    }
  }

  //create an insight
  const [insights, setInsights] = useState([]);

  let [response, setResponse] = useState("i am ai"); // Add the state for the input message

  // Function to handle submiting an insight
  const [loading, setLoading] = useState(false);
  //alerts
  const handleButtonClick = () => {
    if (inputText.trim() == "") {
      alert("Please add insights before submitting.");
      return;
    }

    //check if the input from user is empty
    console.log(inputText);
    if (inputText.length !== 0) {
      setLoading(true);
      axios
        .post("http://127.0.0.1:8000/add_insight/", { message: inputText })
        .then((response) => {
          const answer = response.data.response;

          // Add the user's input and AI response to the insights state
          setInsights((prevInsights) => [
            ...prevInsights,
            { title: inputText, text: answer }, // The AI response
          ]);

          setInputText(""); // Clear the input after sending the message

          setLoading(false);
        })
        .catch((error) => {
          console.error("Error sending message:", error);

          setLoading(false);
        });
    }
  };
  const isButtonDisabled = false;
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      alert("File selected: ${file.name}");
    } else {
      alert("Please upload your Design Thinking insights.");
    }
  };
  function openFileInput() {
    const fileInput = fileInputRef.current;
    
    fileInput.click();
  }

  
function findInsights () {


}



  return (
    <>
      <>
        {/* Display the list of chat messages */}
        {insights.map((inputText, index) => (
          <InsightsResponse key={index} message={inputText} />
        ))}
      </>

      <div position="fixed">
        <Stack
          spacing={2}
          direction="row"
          alignItems="center"
          justifyContent="center"
          position="absolute"
          bottom="2px"
          left="0"
          right="0"
        >
          <label htmlFor="file-upload" style={{ width: 220 }}>
            <input
              type="file"
              id="file-upload"
              style={{ display: "none" }}
              onChange={handleFileUpload}
            />
            <Button
              variant="contained"
              size="large"
              //component="san"
              sx={{ bgcolor: "#5f249f" }}
              onClick={openFileInput}
            >
              <Stack
                direction="row"
                justifyContent="centre"
                alignItems="center"
                spacing={4}
              >
                <Typography>Upload File</Typography>
                <input
                  type="file"
                  id="file-input"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={uploadFile}
                  multiple
                />
                <img
                  src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 47.68 46'%3E%3Cg id='icons'%3E%3Cpath d='M34.82,0H8.68V19H5.5C3.49,19,2,20.21,1,22.59a15.6,15.6,0,0,0-1,5.77C0,34.22,2.23,38,5.69,38h3v8h39V11.71Zm-.19,2.53L43.93,11H36.68a2,2,0,0,1-2-2ZM10.68,44V38h16V36h-21C3.15,36,2,32,2,28.36a13.7,13.7,0,0,1,.84-5C3.81,21,5,21,5.5,21H29.68c1.89,0,2.88,2.5,2.88,5,0,3.12-2,5-3.35,5H13.72c-1.34,0-1.81-1.76-1.81-2.14,0-1.45,1-2.86,2.08-2.86H26.68V24H14c-2.17,0-4.08,2.27-4.08,4.86,0,1.4,1.15,4.14,3.81,4.14H29.21c2.58,0,5.35-2.82,5.35-7,0-3.36-1.53-7-4.88-7h-19V2h22V9a4,4,0,0,0,4,4h9V44Z' fill='white'/%3E%3C/g%3E%3C/svg%3E"
                  variant="contained"
                  loading="eager"
                  width="15%"
                  height="15%"
                />
              </Stack>
            </Button>
          </label>
          <Button
              variant="contained"
              size="large"
              //component="san"
              disabled={isButtonDisabled}
              sx={{ bgcolor: "#5f249f" }}
              onClick={findInsights}
            >
              Find Insights
            </Button>

          <Box
            sx={{ bgcolor: "e5d5f6", width: 500, maxWidth: "100%" }}
            minWidth="500px"
            padding="5px"
            borderRadius="5px"
          >
            <TextField
              id="outlined-basic"
              type="text"
              value={inputText}
              onChange={handleInputChange}
              label="Add Insights"
              variant="outlined"
              margin="normal"
              fullWidth
            />
          </Box>
          {loading && <div className={classes.root}>Loading...</div>}
          <Button
            variant="contained"
            onClick={handleButtonClick}
            sx={{ bgcolor: "#5F249F" }}
          >
            Submit
          </Button>
        </Stack>
      </div>

      {/* Loading indicator */}
    </>
  );
};

export default AddInsightsGroup;
