import * as React from "react";
import { useState , useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import SendIcon from "@mui/icons-material/Send";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { useMsal } from "@azure/msal-react";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { NavLink } from "react-router-dom";
import { BlobServiceClient } from "@azure/storage-blob";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "#ffffff",
  border: "2px solid #5F249F",
  boxShadow: 24,
  p: 4,
};


const LandingSection = (props) => {
  const history = useHistory();
  const [projectName, setProjectName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const roles = ["Client", "UI/UX Designer", "Design Thinker"];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const { accounts } = useMsal();
  const { instance } = useMsal();
  let lastid = 0;
  const handleChangeProjectName = (event) => {
    setProjectName(event.target.value);
  };

  const handleChangeDescription = (event) => {
    setDescription(event.target.value);
  };

  const handleRoleChange = (event) => {
    setSelectedRole(event.target.value);
  };

  const [project, setProject] = useState([]);
    useEffect(() => {
      fetchProjectData();
      axios
        .get("http://127.0.0.1:8000/api/project/")
        .then((response) => {
          setProject(response.data);
        })
        .catch((err) => console.log(err));
    }, []);
    console.log("projects:", project);

    const fetchProjectData = () => {
      axios
        .get("http://127.0.0.1:8000/api/project/")
        .then((response) => {
          setProject(response.data);
        })
        .catch((err) => console.log(err));
    };
    if (project.length > 0) {  
      console.log("last project:", project[project.length - 1].projectId); 
      lastid = project[project.length - 1].projectId;
    } else {  
      console.log("No projects found");
    }  

    const id = [];

      //iterate through all the projects
        //if the userid is one of the users listed inside the project
        for (let x in project) {
          // console.log('inside project', x)
          
          let usersInPorject = project[x].users;
          // console.log('users inside',x,'project', usersInPorject);
          // console.log('PROJECT NAME:', project[x].projectName);

          usersInPorject.forEach((element) => {
            //if the userid is one of the users listed in the project
            //console.log('inside project', x)
            //console.log('element: ', element);
            //console.log('props username: ', props.username);
            if (element == props.username) {
              console.log('inside user array');

              //add it to the ID to the list
              id.push(project[x].projectId);
            }
          });
        }  

  const handleCreateProject = async (id) => {
    const account = instance.getActiveAccount();
    if (!account) {
      console.error("No user account available.");
      return;
    }

    const user_name = account.username;
    const user_folder = user_name.slice(0, -8);
    const formData = new FormData();

    try {
      let projectResponse; // Declare projectResponse outside the try block

      // Make the request to create the project
      projectResponse = await axios.post("http://127.0.0.1:8000/api/project/", {
        projectName: projectName,
        description: description,
      });

      console.log("Project created:", projectResponse.data);

      // Use the obtained projectId to create the user project association

      formData.append("project", projectResponse.data.projectId);
      formData.append("userEmail", user_name);
      formData.append("role", selectedRole);


      const createFolder = async () => {
        const blobSAS =
          "?sv=2022-11-02&ss=bf&srt=sco&sp=wlactfx&se=2023-08-31T02:37:11Z&st=2023-07-12T18:37:11Z&spr=https&sig=7nf%2BjwLlFir3yGp7CJ9gWInQ4ivS0%2BaUG2%2FLGTLXRYg%3D";

        const blobSasUrl = `https://dxcproject2storage.blob.core.windows.net/$web/${blobSAS}`;
        const blobServiceClient = new BlobServiceClient(blobSasUrl);
        const containerClient = blobServiceClient.getContainerClient(user_folder);
        const folderName = projectName;
        const fileName = "index.html";
        const blobName = `${folderName}/${fileName}`;
        const content = "<html><body><h1>Please create your Prototype...</h1></body></html>";
        const blobClient = containerClient.getBlockBlobClient(blobName);
        const options = { blobHTTPHeaders: { blobContentType: 'text/html' } };
        const response = await blobClient.upload(content, content.length, options);
        console.log('Response:', response);
      };

      createFolder();

      const userProjectResponse = await axios.post(
        "http://127.0.0.1:8000/api/createUserProject/",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded", // Set the content type to form data
          },
        }
      );

      console.log("-->>>>>>>>>>>>>>>>>>>>>>>>>BACKEND project created:", userProjectResponse.data);
      handleClose();
    } catch (error) {
      if (error.response) {
        console.error("Error creating project:", error.response.data);
      } else if (error.request) {
        console.error("Error making request:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
    const datas = {
      pname: projectName,
      ppID: id,
      // protoArray: {thePrototype},
      pDesc: description,
    };
    const queryString = new URLSearchParams(datas).toString();
    //Pushes data to the next project space page
    history.push(`/ProjectSpace?${queryString}`);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Stack>
        <Button
          variant="outlined"
          sx={{ width: "75%", alignSelf: 'center'}}
          onClick={handleOpen}
        >
          <img
            src={require("../Icons/Create.png")}
            loading="eager"
            alt="Symbol for Create"
            width="100%"
            height="100%"
          />
        </Button>
        <Typography 
           sx={{color: '#5F249F', textAlign: 'center'}}
           >
            <h3>
             Create New Project 
            </h3>
        </Typography>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Give your project a name
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Make sure it's unique so you can find it later
          </Typography>
          <br></br>

          <TextField
            fullWidth
            label="Project Name"
            value={projectName}
            onChange={handleChangeProjectName}
            sx={{ margin: "1%", padding: "1%" }}
            InputLabelProps={{
              style: {
                color: "#000000", // Change the color of the input label
              },
            }}
            InputProps={{
              style: {
                color: "#000000", // Change the color of the input text
              },
            }}
          />

          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={handleChangeDescription}
            sx={{ margin: "1%", padding: "1%" }}
            InputLabelProps={{
              style: {
                color: "#000000", // Change the color of the input label
              },
            }}
            InputProps={{
              style: {
                color: "#000000", // Change the color of the input text
              },
            }}
          />

          <InputLabel sx={{ color: "#000000", marginLeft: "20px" }}>Role</InputLabel>
          <Select
            value={selectedRole}
            onChange={handleRoleChange}
            sx={{
              color: "#000000",
              minWidth: "300px", // Adjust the width as needed
              height: "5%",
              marginLeft: "14px",
              "& .MuiSelect-icon": {
                color: "#000000", // Change the color of the dropdown icon
              },
            }}
          >
            {roles.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>

          <br></br>

          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            spacing={2}
            padding={2}
          >
            <Button
              variant="outlined"
              sx={{
                color: "#000000",
                backgroundColor: "#FFFFFF",
                "&:hover": { backgroundColor: "#5F249F", color: "#FFFFFF" },
              }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: "#000000",
                backgroundColor: "#FFFFFF",
                "&:hover": { backgroundColor: "#5F249F", color: "#FFFFFF" },
              }}
              endIcon={<SendIcon />}
              onClick={async () => {
                await handleCreateProject(); // Wait for handleCreateProject to finish
                history.push({
                  pathname: "/CreateProject",
                  state: {
                    projectName: projectName,
                    description: description,
                    selectedRole: selectedRole,
                  },
                });
              }}
            >
              Create
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Stack>     
        <Button
          variant="outlined"
          sx={{ width: "75%", alignSelf: 'center'}}
          component={NavLink}
          to="/OpenExistingProject"
        >
          <img
            src={require("../Icons/Search.png")}
            loading="eager"
            alt="Symbol for Search"
            width="100%"
            height="100%"
          />
        </Button>
        <Typography 
          sx={{color: '#5F249F', textAlign: 'center'}}
        >
          <h3>
            View Existing Projects
          </h3>
        </Typography>
      </Stack>
    </div>
  );
};

export default LandingSection;