import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import UserContext from "./ContextAPI/Context";
import {
  MsalProvider,
  AuthenticatedTemplate,
} from "@azure/msal-react";
import HomePage from "./Pages/HomePage";
import DocumentSpace from "./Pages/DocumentSpace";
import OpenExistingProject from "./Pages/OpenExistingProject";
import ProjectSpace from "./Pages/ProjectSpace";
import ShareExisting from "./Pages/ShareExisting";
import ShareInsights from "./Pages/ShareInsights";
import Chat from "./Pages/Chat";
import Feedback from "./Pages/Feedback";
import { PageLayout } from "./Authentication/PageLayout";
import OpenInsights from "./Pages/OpenInsights";
import TrashPage from "./Pages/TrashPage";
import InboxPage from "./Pages/InboxPage";
import DraftsPage from "./Pages/DraftsPage";
import CreateProject from "./Pages/CreateProject";
import axios from "axios";
import CreatePrototype from "./Pages/CreatePrototype";
import ProjectSpaceCreateProject from "./Pages/ProjectSpaceCreateProject";

const App = ({ instance }) => {
  // State for user data
  const [name, setname] = useState("");
  const [username, setUserName] = useState("");
  const [documentsList, setDocumentsList] = useState([]);
  const [draftsList, setDraftsList] = useState([]);
  const [trashList, setTrashList] = useState([]);
  const [documentToID, setDocumentToID] = useState(new Map());

  // Function to get user data
  const handleUserData = () => {
    const account = instance.getAllAccounts()[0];
    if (account) {
      setname(account.name);
    }

    const activeAccount = instance.getActiveAccount();
    if (activeAccount) {
      setUserName(activeAccount.username);
      console.log(account.username);
    }
  };
  useEffect(() => {
    axios
      .get("http://127.0.0.1:8000/api/Document/")
      .then((response) => {
        const documents = response.data;
        const documentsList = [];
        const draftsList = [];
        const trashList = [];
        const documentToID = new Map();

        for (const document of documents) {
          if (document.documentStatus === "uploads") {
            documentsList.push(document.documentName);
            documentToID.set(document.documentName, document.documentLink);
          } else if (document.documentStatus === "drafts") {
            draftsList.push(document.documentName);
            documentToID.set(document.documentName, document.documentLink);
          } else {
            trashList.push(document.documentName);
            documentToID.set(document.documentName, document.documentLink);
          }
        }

        setDocumentsList(documentsList);
        setDraftsList(draftsList);
        setTrashList(trashList);
        setDocumentToID(documentToID);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <MsalProvider instance={instance}>
      <UserContext.Provider value={name}>
        <Router>
          <PageLayout>
            <Switch>

              <Route exact path="/">
                <AuthenticatedTemplate>
                  <HomePage
                    handleUserData={handleUserData}
                    username={username}
                    documentsList={documentsList}
                    draftsList={draftsList}
                    trashList={trashList}
                    documentToID={documentToID}
                  />
                </AuthenticatedTemplate>
              </Route>

              <Route path="/Documents">
                <AuthenticatedTemplate>
                  <DocumentSpace
                    handleUserData={handleUserData}
                    username={username}
                    documentsNameList={documentsList}
                    documentLink={documentToID}
                  />
                </AuthenticatedTemplate>
              </Route>

              <Route path="/Inbox">
                <AuthenticatedTemplate>
                  <InboxPage />
                </AuthenticatedTemplate>
              </Route>

              <Route path="/Drafts">
                <AuthenticatedTemplate>
                  <DraftsPage
                    handleUserData={handleUserData}
                    username={username}
                    documentsNameList={draftsList}
                    documentLink={documentToID}
                  />
                </AuthenticatedTemplate>
              </Route>

              <Route path="/Trash">
                <AuthenticatedTemplate>
                  <TrashPage
                    username={username}
                    handleUserData={handleUserData}
                    documentsNameList={trashList}
                    documentLink={documentToID}
                  />
                </AuthenticatedTemplate>
              </Route>

              <Route path="/OpenExistingProject">
                <AuthenticatedTemplate>
                  <OpenExistingProject username={username} />
                </AuthenticatedTemplate>
              </Route>

              <Route path="/ProjectSpace">
                <AuthenticatedTemplate>
                  <ProjectSpace username={username} />
                </AuthenticatedTemplate>
              </Route>

              <Route path="/ProjectSpaceCreateProject">
                <AuthenticatedTemplate>
                  <ProjectSpaceCreateProject username={username} />
                </AuthenticatedTemplate>
              </Route>

              <Route path="/ShareExisting">
                <AuthenticatedTemplate>
                  <ShareExisting username={username} />
                </AuthenticatedTemplate>
              </Route>

              <Route
                path="/openInsights/:projectId"
                render={(props) => <OpenInsights {...props} />}
              >
                <AuthenticatedTemplate>
                  <OpenInsights username={username} />
                </AuthenticatedTemplate>
              </Route>

              <Route path="/ShareInsights">
                <AuthenticatedTemplate>
                  <ShareInsights username={username} />
                </AuthenticatedTemplate>
              </Route>

              <Route path="/CreatePrototype">
                <AuthenticatedTemplate>
                  <CreatePrototype username={username} />
                </AuthenticatedTemplate>
              </Route>

              <Route path="/CreateProject">
                <AuthenticatedTemplate>
                  <CreateProject username={username} />
                </AuthenticatedTemplate>
              </Route>

              <Route path="/Feedback">
                <AuthenticatedTemplate>
                  <Feedback />
                </AuthenticatedTemplate>
              </Route>

              <Route path="/Chat">
                <AuthenticatedTemplate>
                  <Chat 
                  handleUserData={handleUserData}
                  username={username} 
                  />
                </AuthenticatedTemplate>
              </Route>

            </Switch>
          </PageLayout>
        </Router>
      </UserContext.Provider>
    </MsalProvider>
  );
};

export default App;
