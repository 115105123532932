import React from 'react'
import DocumentSpaceButtons from '../components/DocumentSpaceButtons'
import PersistentDrawer from '../components/PersistentDrawer'
import DocumentsDisplay from '../components/DocumentsDisplay';
import { Box, Container, Typography } from '@mui/material';

export default function InboxPage () {

    return (
        <div>
         <PersistentDrawer/> 
     
         <Container fixed maxWidth="sm">

            <Typography variant='body1' align='centre' style={{ align: 'center'}} > Your notifications will appear here </Typography>
        </Container>

        </div>
      );
}
