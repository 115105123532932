import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ListItemText } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(95, 36, 159)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: '#5F249F',
        marginRight: theme.spacing(1.5),
      },
      '&:active': {

      },
      '&:hover': { backgroundColor: "#e5d5f6" },
    },
  },
}));



//From backend retrieve all the project names the user has and store them in options

const ProjectSpaceDropdown = (props) => {

  const options = props.projectList;

  // console.log(props.projectList);
  const currentOption = props.currentProject;
  console.log(currentOption);
  //const options= ["Apple", "Orange",];





  //when loading in select the current project,
  //go through the list of projects 
  //if it matches the current options string
  //get the index and store it in a variable

  let element = 0;
  for (let index = 0; index < options.length; index++) {
    if (currentOption === options[index]) {
      element = props.projectID.get(options[index]);
      element = element - 1;
      console.log('THE ELEMENT', element);
    }
  }



  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(element);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };



  const history = useHistory();

  const handleMenuItemClick = (event, index) => {
    console.log('THIS IS THE INDEX SELECTED', selectedIndex)
    setSelectedIndex(index);
    setAnchorEl(null);
    console.log('SELECTED A PROJECT BUTTON CLICKED');
    console.log(index);


    //update the url with the name, id , desc
    console.log('name', options[index]);
    //get the id from the map
    //find the value in the map with the key current option
    console.log('id', props.projectID.get(options[index]));
    //get the description for that same index
    console.log('desc', props.projectDesc[index]);

    const data = {
      pname: options[index],
      ppID: props.projectID.get(options[index]),
      // protoArray: {thePrototype},
      pDesc: props.projectDesc[index]
    };

    const queryString = new URLSearchParams(data).toString();
    //Pushes data to the next project space page
    history.push(`/ProjectSpace?${queryString}`);

  };


  const [hover, setHover] = React.useState();
  const handleMouseIn = () => {
    setHover(true);
  };

  const handleMouseOut = () => {
    setHover(false);
  };

  return (
    <div >


      <Button sx={{ bgcolor: hover ? '#9659d9' : '#5F249F' }} size='large'
        id="demo-customized-button"
        padding={4}
        aria-controls={open ? 'demo-customized-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClickListItem}
        endIcon={<KeyboardArrowDownIcon />}
        value={10}
        onMouseOver={handleMouseIn}
        onMouseOut={handleMouseOut}
      >


        <ListItemText
          primary={options[selectedIndex]} />

      </Button>

      {console.log('selected index BEFORE RENDER', selectedIndex)}

      <StyledMenu
        selected={selectedIndex}
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
      // onClose={handleClose}  
      >
        {options.map((option, index) => (
          <MenuItem
            key={option}
            disabled={index === selectedIndex}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}

      </StyledMenu>

    </div>
  );
}

export default ProjectSpaceDropdown;
