import * as React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { Stack } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { useHistory } from "react-router-dom"
import ShareInsights from '../Pages/ShareInsights';


const InsightsHeaderButton = (props) => {
  //#5F249F DXC purple
  //button style
    const [iShareButtonVariant, setiShareButtonVariant] = React.useState('contained');
    const [iCloseButtonVariant, setiCloseButtonVariant] = React.useState('contained');

  //disable/ enable button
    const [shareClickable, setshareClick] = React.useState(false);
    const [closeClickable, setcloseClick] = React.useState(false);


    const ShareInsight  = () => {
      if (iShareButtonVariant === 'contained') {
        //change look of button
        setiShareButtonVariant('outlined');
        //disable ___ button
        setcloseClick(true);

        
        //only show ___
      }
      else {
        setiShareButtonVariant('contained');
        setcloseClick(false);
        
      } 
     };

     const CloseInsightsPage  = () => {
     if (iCloseButtonVariant === 'contained') {
      setiCloseButtonVariant('outlined');
      //disable ___ button
      setshareClick(true);
    
     //only show ___
    }
    else {
      setiCloseButtonVariant('contained');
      setshareClick(false);

    } 
     };
     
     const handleGoBack = () => {
      window.history.back();
    };
   
    return (
       <Box 
       style={{position: 'fixed', bottom: '100', right: '0'}}
       sx={{width: 500}}
       padding={4}
       >
        <Stack direction="row"  justifyContent="flex-end" alignItems="center" spacing={4}>
          {/* <Button 
          sx={{bgcolor: '#e5d5f6', borderColor: "#D9D9D6"}}  
          onClick={ShareInsights}
          size="large"
          fullWidth
          >
                <Typography 
                sx={{color: '#5F249F' , borderColor: "#D9D9D6"}}
                variant="button"
                 >
                  Share 
                </Typography>
            </Button > */}
          <ShareInsights />

          <Button 
          sx={{bgcolor: '#e5d5f6'}}  
          size="large"
          fullWidth
        //  component={NavLink}
        //  to={'CreateProject'}
        onClick={handleGoBack}
          >
              <Typography 
              sx={{color:  '#5F249F' }} 
              variant="button"
              // component={NavLink} to={props.closeButton} 
              
               >
               Close 
               </Typography>
          </Button>
        </Stack>
       </Box>
    );}
    
    export default InsightsHeaderButton;