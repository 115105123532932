import React from 'react'
import ProjectItems from '../components/ProjectItems';
import ProjectSpaceHeader from '../components/ProjectSpaceHeader';
import ProjectPrototypeItem from '../components/ProjectPrototypeItem';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import axios from 'axios'
import { Box, Button, ButtonGroup, Typography } from '@mui/material';
import DialogueBox from '../components/DialogueBox';


export default function ProjectSpaceCreateProject(props) {

  const [project, setProject] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const name = params.get("pname");
  const id = params.get("ppID");
  const desc = params.get("pDesc");
  //const name = location.state?.pname || ""; // Project name
  //const id = location.state?.ppID || ""; // Project ID
  //const desc = location.state?.pDesc || ""; // Project descriptio

  const handleOpenForm = () => setOpenForm(true);
  const handleCloseForm = () => setOpenForm(false);
  const handleDialogueSubmit = (figmaUrl) => {
    console.log('Submitted Figma URL:', figmaUrl);
  };

  useEffect(() => {
    fetchProjectData();
    axios
      .get("http://127.0.0.1:8000/api/project/")
      .then((response) => {
        setProject(response.data);
      })
      .catch((err) => console.log(err));
  }, []);
  const fetchProjectData = () => {
    axios
      .get("http://127.0.0.1:8000/api/project/")
      .then((response) => {
        setProject(response.data);
      })
      .catch((err) => console.log(err));
  };

  const [prototype, setPrototype] = useState([]);
  useEffect(() => {
    axios
      .get("http://127.0.0.1:8000/api/prototype/")
      .then((response) => {
        setPrototype(response.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const projectNamesList = [];
  const projectDescriptionList = [];
  const nameToID = new Map();
  let creator =''
  let createdTime =''

  for (let x in project) {
    let usersInPorject = project[x].users;
    creator = project[x].users[0];
    createdTime = project[x].createdAt;

    usersInPorject.forEach((element) => {
      if (element === props.username) {
        projectNamesList.push(project[x].projectName);
        projectDescriptionList.push(project[x].description);
        nameToID.set(project[x].projectName, project[x].projectId);
      }
    });
  }

  const protoList = [];

  for (let index = 0; index < prototype.length; index++) {
    if ( id == prototype[index].projectName) {
      protoList.push(prototype[index]);
    }
  }

  function createPrototypes(
    cB,
    fS,
    lastM,
    lastMB,
    link,
    pN,
    protoName,
    versionNum
  ) {

    return (
      <ProjectPrototypeItem
        createdBy={cB}
        feedbackStatus={fS}
        lastModifiedBy={lastM}
        lastModified={lastMB}
        link={link}
        projectName={pN}
        prototypeName={protoName}
        versionNumber={versionNum}
      />
    );
  }
  const projectFiles = [
    <ProjectItems
      projectName={name}
      projectId={id}
      creator={creator}
      creationDate={createdTime}
    />,
  ];

  if (protoList.length !== 0) {

    for (let index = 0; index < protoList.length; index++) {
      projectFiles.push(
        createPrototypes(
          protoList[index].createdBy,
          protoList[index].feedback_status,
          protoList[index].lastModifiedBy,
          protoList[index].lastModified,
          protoList[index].link,
          protoList[index].projectName,
          protoList[index].prototypeName,
          protoList[index].versionNumber
        )
      );
    }
  }

  return (
    <div>
      <ProjectSpaceHeader
        projectList={projectNamesList}
        projectID={nameToID}
        projectDesc={projectDescriptionList}
        currentProject={name} />

      <Box sx={{ justifyContent: 'center' }}>
        <Typography> {desc}</Typography>
      </Box>

      <br></br>
      {projectFiles}
      <br></br>      

      <Box
        style={{ position: "absolute", bottom: "100", right: "0" }}
        padding={4}
      >
        <ButtonGroup
          sx={{
            ".MuiButtonGroup-grouped:not(:last-of-type)": {
              borderColor: "#D9D9D6",
            },
          }}
          variant="contained"
          size="large"
          aria-label="outlined primary button group"
          pa
        >
          <Button onClick={handleOpenForm}>
            <AddCircleIcon />
            <Typography paddingLeft={1}>
              Create prototype
            </Typography>
          </Button>
        </ButtonGroup>
      </Box>

      <DialogueBox open={openForm} onClose={handleCloseForm} onSubmit={handleDialogueSubmit} />

    </div>
  );

}
