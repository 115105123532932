import React from "react";
import AddInsightsGroup from "../components/AddInsightsGroup";
import OpenInsightsHeader from "../components/OpenInsightsHeader";
import ProjectItems from "../components/ProjectItems";
import { useParams } from "react-router-dom";
import { useState } from 'react';
import InsightsResponse from "../components/InsightsResponse";
import Box from '@mui/material/Box';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';
import Draggable from 'react-draggable';
import { AddBox } from "@mui/icons-material";

export default function OpenInsights() {
  const theme = createTheme({
    palette: {
      background: {
        default: '#e5d6f6', // Replace with your desired background color
      },
    },
  });

  const { projectId } = useParams();

  


  return (
    <>
   <ThemeProvider theme={theme}>
      <CssBaseline />
      
       <Box><OpenInsightsHeader InsightName={projectId}/></ Box>
     
      <AddInsightsGroup projectId={projectId} style={{position: 'fixed', bottom: '150'}}/> 
     
      </ThemeProvider>
      </>
  );
}
