import React from 'react';
import PersistentDrawer from '../components/PersistentDrawer';
import { useIsAuthenticated } from '@azure/msal-react';

export const PageLayout = (props) => {
    const isAuthenticated = useIsAuthenticated();

    return (
        <>
            <PersistentDrawer />
            {!isAuthenticated ? (
                <div>
                    <center>
                        <div>
                            <img src={require("../Icons/Protobot.png")} style={{ margin: "4%" }} width="50%" alt="Protobot logo" />
                        </div>
                        <h2>
                        "The future of building Prototypes"
                        </h2>
                    </center>
                    <br />
                    <br />
                    {props.children}
                </div>
            ) : (
                props.children
            )}
        </>
    );
};
